<ng-content />

@if (errors()) {
	<div class="error">
		<header class="error__header">
			<ion-icon
				class="error__alert"
				name="alert-circle"
			/>
			<span class="error__title">{{ title() }}</span>
		</header>
		<wndrc-validation-message
			data-testid="error"
			class="error__message"
			[errors]="errors()"
		/>
	</div>
}
