import { ChangeDetectionStrategy, Component, Signal, contentChild, input } from '@angular/core';
import { FormGroupDirective, ValidationErrors } from '@angular/forms';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { EMPTY, distinct, filter, map, merge, switchMap } from 'rxjs';
import { listenControlTouched } from '@wndr/common/core/utils/rxjs/listen-control-touched';
import { AppValidators } from '@wndr/common/core/utils/validators';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { alertCircle } from 'ionicons/icons';

import { ValidationMessageComponent } from '../validation-message/validation-message.component';

/** Wrapper component that queries errors from form control directive and presents it. */
@Component({
	selector: 'wndrc-form-error-wrapper',
	standalone: true,
	imports: [ValidationMessageComponent, IonIcon],
	templateUrl: './form-error-wrapper.component.html',
	styleUrls: ['./form-error-wrapper.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorWrapperComponent {

	/** Title. */
	public readonly title = input('Something went wrong');

	/** Provided error text. */
	public readonly errorText = input<string | null>(null);

	private readonly form = contentChild.required(FormGroupDirective, { descendants: true });

	/** Validation errors. */
	protected readonly errors = this.initializeValidationErrors();

	public constructor() {
		addIcons({ alertCircle });
	}

	private initializeValidationErrors(): Signal<ValidationErrors | null> {
		return toSignal(merge(
			toObservable(this.form).pipe(
				distinct(),
				switchMap(_form =>
					merge(
						_form.statusChanges ?? EMPTY,
						listenControlTouched(_form.control).pipe(filter(Boolean)),
					).pipe(map(() => _form.errors))),
			),
			toObservable(this.errorText).pipe(
				map(value => value !== null ? AppValidators.buildAppError(value) : null),
			),
		), { initialValue: null });
	}
}
